<template>
  <div>
    <h2 class="page-title-bar">
      <img class="imgageproperty" src="@/assets/img/errorlist.png" alt="save" />
      {{ $t("ESL Product Information") }}
    </h2>

    <div class="searchArea">
      <label class="lastupdated1">{{ $t("Last Updated Time") }}</label>
      <label
        ><span class="lastupdateddate1">{{ lastUpdatedTime }}</span></label
      >
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :hide-default-footer="true"
      :options.sync="options"
      :server-items-length="totalVisiblePages"
      class="tbl-type01 mt-10"
    >
      <template slot="no-data">
        <p>{{ $t("No data available") }}</p>
      </template>
    </v-data-table>

    <div class="table-options">
      <div>
        <v-btn
          :disabled="btnDisabledExport || btnDisabledExportCheckList"
          @click="exportUserInfoList"
          class="btn ml-2"
          text
        >
          {{ $t("Export") }}
        </v-btn>
      </div>
      <div>
        <div class="pageInfo">{{ pageInfoText }}</div>
        <v-pagination
          v-model="page"
          @input="paging"
          :length="totalPages"
          :total-visible="totalVisiblePages"
          color="#2f3b4c"
        ></v-pagination>
      </div>
    </div>
    <a ref="link" :style="{ display: 'none' }" />
  </div>
</template>

<script>
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
import { mapGetters } from 'vuex'
// import { exportFiles } from '@/plugins/exporter'
import commons from '@/plugins/commons'

const requests = {
  getProuctInfo: {
    method: 'get',
    url: '/metro/report/product-info'
  }
}

export default {
  name: 'ESLProductInfo',

  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      btnDisabledExport: null,
      pageCount: 0,
      tableItems: [],
      selected: [],
      options: {},
      ROW_CLASS: 'row-class',
      page: 1,
      totalPages: 0,
      pageInfoText: null,
      totalVisiblePages: 7,
      exportDisabled: false,
      userEditItem: {},
      requestConfig: {},
      searchParam: {
        company: null,
        store: null,
        page: null,
        size: '10',
        sort: 'code,asc'
      },
      startIndex: null,
      totalData: [],
      endindex: null,
      lastUpdatedTime: null
    }
  },
  computed: {
    tableHeaders () {
      return [
        {
          text: this.$t('SYSTEM'),
          sortable: false,
          align: 'center',
          value: 'system',
          width: '95px'
        },
        {
          text: this.$t('TYPE'),
          sortable: false,
          align: 'center',
          value: 'type',
          width: '85px'
        },
        {
          text: this.$t('TOTAL'),
          sortable: false,
          value: 'total',
          align: 'center',
          width: '85px'
        },
        {
          text: this.$t('> 8YEAR'),
          sortable: false,
          align: 'center',
          value: 'year8more',
          width: '95px'
        },
        {
          text: this.$t('7 YEAR'),
          sortable: false,
          value: 'year7',
          width: '90px',
          align: 'center'
        },
        {
          text: this.$t('6 YEAR'),
          sortable: false,
          value: 'year6',
          width: '90px',
          align: 'center'
        },
        {
          text: this.$t('5 YEAR'),
          sortable: false,
          value: 'year5',
          width: '90px',
          align: 'center'
        },
        {
          text: this.$t('<5 YEAR'),
          sortable: false,
          value: 'year5less',
          width: '95px',
          align: 'center'
        },
        {
          text: this.$t('UNKNOWN'),
          sortable: false,
          value: 'unknown',
          width: '110px',
          align: 'center'
        }
      ]
    },
    btnDisabledExportCheckList () {
      var flag = true
      if (this.tableItems !== undefined && this.tableItems.length) flag = false
      return flag
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    }
  },
  watch: {
    options (val) {
      // const columnInfo = {
      //   system: 'system',
      //   type: 'type',
      //   time: 'lastResponseTime',
      //   total: 'total',
      //   year8more: 'year8more',
      //   year7: 'year7',
      //   year6: 'year6',
      //   year5: 'year5',
      //   year5less: 'year5less',
      //   unknown: 'unknown'
      // }
      // let sortParam = null
      // if (val.sortBy.length && val.sortDesc[0]) {
      //   sortParam = columnInfo[val.sortBy[0]] + ',DESC'
      // } else if (val.sortBy.length && !val.sortDesc[0]) {
      //   sortParam = columnInfo[val.sortBy[0]] + ',ASC'
      // }
      // if (!commons.isNull(sortParam)) this.searchParam.sort = sortParam
      // const sortingData = this.sortingBuilingData(
      //   this.searchParam.sort,
      //   this.tableItems
      // )
      // this.tableItems = sortingData
      // this.updatePages(this.tableItems, this.page)
      // this.page = 1
      // this.getEslProductInfo(1)
    },
    store: {
      handler () {
        this.page = 1
        this.getEslProductInfo(this.page)
      }
    }
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  mounted () {
    this.getEslProductInfo(1)
    this.$store.dispatch('auth/getDisabledBtn', '5101').then((flag) => {
      this.exportDisabled = flag
    })
    EventBus.$emit('enableSelectedStores', true)

    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.getEslProductInfo(1)
    }
  },
  methods: {
    paging (page) {
      this.getEslProductInfo(page)
    },
    exportUserInfoList () {
      const exportdata = {
        guiMap: {
          title_productinfo: this.$t('ESL Product Information'),
          store_name: this.$t('STORE NAME'),
          system: this.$t('SYSTEM'),
          type: this.$t('TYPE'),
          total: this.$t('TOTAL'),
          year: this.$t('YEAR'),
          unknown: this.$t('UNKNOWN')
        },
        msgMap: {
          M0124: this.$t('date Edited'),
          M0125: this.$t('Signature Dept. Head'),
          M0126: this.$t('Error Code'),
          M0127: this.$t('ESL No'),
          M0128: this.$t('ESL no reception'),
          M0129: this.$t('ESL with an incorrect price'),
          M0130: this.$t('ESL false indication info level'),
          M0131: this.$t('Destroyed ESL'),
          M0132: this.$t('Other')
        }
      }

      const params = { company: this.user.company, store: this.store.code, lang: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'] }
      const config = { params: params }
      config.responseType = 'arraybuffer'
      this.$utils
        .callAxiosWithBody(
          codes.requests.exportProductInfoList.method,
          codes.requests.exportProductInfoList.url,
          exportdata,
          config
        )
        .then(res => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'productInfo.pdf'
          link.click()
        })
        .catch(error => {
          console.debug(error)
        })
    },
    insertIndex (tableData, headers) {
      let idx = Number(headers['x-number'] * headers['x-size']) + 1
      tableData = tableData.map((tableItems) => {
        tableItems.no = idx
        idx++
        return tableItems
      })
      return tableData
    },
    sortingBuilingData (sortingParam, tableData) {
      const sortingParamDetails = sortingParam.split(',')
      const keyType = sortingParamDetails[0]
      const orderType = sortingParamDetails[1]
      if (keyType === 'code') {
        return tableData
      }
      return tableData.sort(compareValues(keyType, orderType))
      function compareValues (key, order = 'ASC') {
        return function innerSort (a, b) {
          if (
            !Object.prototype.hasOwnProperty.call(a, key) ||
            !Object.prototype.hasOwnProperty.call(b, key)
          ) {
            // property doesn't exist on either object
            return 0
          }
          const varA =
            typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
          const varB =
            typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

          let comparison = 0
          if (varA > varB) {
            comparison = 1
          } else if (varA < varB) {
            comparison = -1
          }
          return order === 'DESC' ? comparison * -1 : comparison
        }
      }
    },
    // generaticStaticData (data) {
    //   const exponinetData = [
    //     {
    //       system: 'Quinn Finch',
    //       type: 'Sweeney Bowen',
    //       total: 40,
    //       year8more: '32}',
    //       year7: 20,
    //       year6: 33,
    //       year5: 36,
    //       year5less: 38,
    //       unknown: 22
    //     },
    //     {
    //       system: 'Iva Terrell',
    //       type: 'Vera Brennan',
    //       total: 24,
    //       year8more: '27}',
    //       year7: 23,
    //       year6: 37,
    //       year5: 35,
    //       year5less: 35,
    //       unknown: 39
    //     },
    //     {
    //       system: 'Deborah Valenzuela',
    //       type: 'Hubbard Hensley',
    //       total: 20,
    //       year8more: '36}',
    //       year7: 25,
    //       year6: 27,
    //       year5: 25,
    //       year5less: 27,
    //       unknown: 39
    //     },
    //     {
    //       system: 'Estella Tyson',
    //       type: 'Miranda Maddox',
    //       total: 26,
    //       year8more: '35}',
    //       year7: 38,
    //       year6: 30,
    //       year5: 23,
    //       year5less: 21,
    //       unknown: 26
    //     },
    //     {
    //       system: 'Chaney Estes',
    //       type: 'Bonnie Beasley',
    //       total: 24,
    //       year8more: '35}',
    //       year7: 25,
    //       year6: 20,
    //       year5: 28,
    //       year5less: 21,
    //       unknown: 23
    //     },
    //     {
    //       system: 'Lourdes Howe',
    //       type: 'Jeannine Waters',
    //       total: 25,
    //       year8more: '23}',
    //       year7: 28,
    //       year6: 22,
    //       year5: 40,
    //       year5less: 26,
    //       unknown: 28
    //     },
    //     {
    //       system: 'Phyllis Mathews',
    //       type: 'Francis Compton',
    //       total: 33,
    //       year8more: '31}',
    //       year7: 31,
    //       year6: 30,
    //       year5: 29,
    //       year5less: 24,
    //       unknown: 26
    //     },
    //     {
    //       system: 'Head Jacobson',
    //       type: 'Sandoval Huffman',
    //       total: 27,
    //       year8more: '38}',
    //       year7: 26,
    //       year6: 31,
    //       year5: 26,
    //       year5less: 28,
    //       unknown: 36
    //     },
    //     {
    //       system: 'Melody Bender',
    //       type: 'Elsie Mullins',
    //       total: 35,
    //       year8more: '38}',
    //       year7: 29,
    //       year6: 24,
    //       year5: 28,
    //       year5less: 25,
    //       unknown: 23
    //     },
    //     {
    //       system: 'Chandler Roberson',
    //       type: 'Araceli Shaw',
    //       total: 29,
    //       year8more: '31}',
    //       year7: 35,
    //       year6: 38,
    //       year5: 31,
    //       year5less: 40,
    //       unknown: 40
    //     },
    //     {
    //       system: 'Day Robertson',
    //       type: 'Millie Leblanc',
    //       total: 29,
    //       year8more: '21}',
    //       year7: 21,
    //       year6: 27,
    //       year5: 24,
    //       year5less: 28,
    //       unknown: 26
    //     },
    //     {
    //       system: 'Patti Ballard',
    //       type: 'Poole Wiggins',
    //       total: 21,
    //       year8more: '32}',
    //       year7: 39,
    //       year6: 20,
    //       year5: 33,
    //       year5less: 30,
    //       unknown: 35
    //     }
    //   ]
    //   return data.concat(exponinetData)
    // },
    updatePages (productInfoDetails, page = 1) {
      this.startIndex = (page - 1) * 10
      this.endindex = this.startIndex + 10
      if (this.endindex > productInfoDetails.length) {
        this.endindex = productInfoDetails.length
      }
      const limitData = []
      for (let i = this.startIndex; i < this.endindex; i++) {
        limitData.push(productInfoDetails[i])
      }
      this.tableItems = limitData
      this.pageInfoText = `${this.startIndex + 1}  ${this.$t('to')} ${this.endindex}, ${
        productInfoDetails.length
      } ` + ' ' + this.$t(' in total')
    },
    handleGetEslProductInfoResponse (res) {
      if (res.status === 204) {
        this.handleGetEslProductInfoError('No content')
        return
      }
      // Refresh ProductinfoList
      let productListInfo
      if (res.data === undefined) {
        productListInfo = []
        // productListInfo = this.generaticStaticData(productListInfo)
        this.lastUpdatedTime = null
      } else {
        productListInfo = res.data.productInfoList
        // productListInfo = this.generaticStaticData(productListInfo)
        this.lastUpdatedTime = res.data.lastUpdatedTime
      }
      this.tableItems = productListInfo
      // Pagination
      this.totalData = productListInfo
      // this.updatePages(productListInfo)
      // this.pageInfoText = `${1} to ${productListInfo.length}, ${
      //   productListInfo.length
      // } in total`
      // let totalPageLength = Math.floor(this.totalData.length / 10)
      // if (this.totalData.length % 10 !== 0) {
      //   totalPageLength++
      // }
      // this.totalPages = totalPageLength
      this.pageInfoText = this.getPageInfoText(res.headers)
      if (commons.isValidStr(res.headers['x-totalpages'])) {
        this.totalPages = res.headers['x-totalpages'] * 1
      }
    },
    getPageInfoText: function (headers) {
      if (headers['x-totalelements'] === 0) return '0 to 0, 0' + ' ' + this.$t('in total')
      return `${headers['x-number'] * 1 * headers['x-size'] + 1} 
      ${this.$t('to')} ${(headers['x-number'] * 1 * headers['x-size']) + headers['x-total-count'] * 1},
       ${headers['x-totalelements'] * 1}` + ' ' + this.$t('in total')
    },
    handleGetEslProductInfoError (error) {
      this.tableItems = []
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = this.getPageInfoText(headers)
      this.totalPages = 0
      console.debug(error)
    },
    getEslProductInfoSortKey (sortKey) {
      if (this.productionType === codes.productionTypes.CLOUD) {
        switch (sortKey) {
          case 'lastUpdateTime':
            sortKey = 'lastModified'
            break
          default:
            break
        }
        return sortKey
      } else {
        switch (sortKey) {
          case 'type':
            sortKey = 'id.type'
            break
          case 'lastUpdateTime':
            sortKey = 'lastModified'
            break
          default:
            break
        }
        return sortKey
      }
    },
    buildParmas: function () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      const params = { company: this.user.company, store: this.store.code }
      let sort = null
      if (sortBy && sortBy.length === 1 && sortDesc && sortDesc.length === 1) {
        const sortKey = sortBy[0]
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = itemsPerPage
      return params
    },
    getEslProductInfo (page) {
      const params = this.buildParmas()
      if (page !== null) params.page = page - 1
      const config = { params: params }
      if (commons.isNull(config.params.store) || config.params.store === '') {
        this.handleGetEslProductInfoError('')
        return
      }
      this.$utils
        .callAxios(
          requests.getProuctInfo.method,
          requests.getProuctInfo.url,
          config
        )
        .then((res) => {
          this.handleGetEslProductInfoResponse(res)
        })
        .catch((error) => {
          this.handleGetEslProductInfoError(error)
        })
    }
  }
}
</script>

<style scoped>
.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 18% !important;
  text-align: left;
}
.lastupdated1 {
  position: absolute;
  right: 46px;
  margin-top: -18px;
  font-size: 14px;
}
.lastupdateddate1 {
  position: absolute;
  right: 46px;
  font-size: 14px;
}
/* .v-application .mt-10 {
  margin-top: 0px !important;
} */
.v-application .text-center {
  text-align: left !important;
}
.imgageproperty {
  margin-right: 14px;
  height: 24px;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("img", {
          staticClass: "imgageproperty",
          attrs: { src: require("@/assets/img/errorlist.png"), alt: "save" }
        }),
        _vm._v(" " + _vm._s(_vm.$t("ESL Product Information")) + " ")
      ]),
      _c("div", { staticClass: "searchArea" }, [
        _c("label", { staticClass: "lastupdated1" }, [
          _vm._v(_vm._s(_vm.$t("Last Updated Time")))
        ]),
        _c("label", [
          _c("span", { staticClass: "lastupdateddate1" }, [
            _vm._v(_vm._s(_vm.lastUpdatedTime))
          ])
        ])
      ]),
      _c(
        "v-data-table",
        {
          staticClass: "tbl-type01 mt-10",
          attrs: {
            headers: _vm.tableHeaders,
            items: _vm.tableItems,
            "hide-default-footer": true,
            options: _vm.options,
            "server-items-length": _vm.totalVisiblePages
          },
          on: {
            "update:options": function($event) {
              _vm.options = $event
            }
          }
        },
        [
          _c("template", { slot: "no-data" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("No data available")))])
          ])
        ],
        2
      ),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: {
                  disabled:
                    _vm.btnDisabledExport || _vm.btnDisabledExportCheckList,
                  text: ""
                },
                on: { click: _vm.exportUserInfoList }
              },
              [_vm._v(" " + _vm._s(_vm.$t("Export")) + " ")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("div", { staticClass: "pageInfo" }, [
              _vm._v(_vm._s(_vm.pageInfoText))
            ]),
            _c("v-pagination", {
              attrs: {
                length: _vm.totalPages,
                "total-visible": _vm.totalVisiblePages,
                color: "#2f3b4c"
              },
              on: { input: _vm.paging },
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            })
          ],
          1
        )
      ]),
      _c("a", { ref: "link", style: { display: "none" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }